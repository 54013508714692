var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"is-info p-8 pt-16",class:_vm.cssVariables.tradeshow -
                _vm.search -
                _vm.result -
                _vm.header -
                _vm.bg -
                _vm.color !=
            null
                ? 'bg-tradeshow-search-result-header'
                : 'bg-white'},[_c('div',{staticClass:"container content"},[_c('div',{staticClass:"text-center max-w-md mx-auto"},[_c('h1',{staticClass:"title is-size-1"},[_vm._v("Vendor Search Results")]),(_vm.exhibitorSearchResults.length)?_c('p',{staticClass:"is-size-4 mb-0 font-semibold"},[_vm._v(" Here are the companies that meet your needs! ")]):_vm._e()])])]),_c('section',{staticClass:"section is-centered p-8",class:_vm.cssVariables.tradeshow -
                _vm.search -
                _vm.result -
                _vm.content -
                _vm.bg -
                _vm.color !=
            null
                ? 'bg-tradeshow-search-result-content'
                : 'bg-white'},[_c('div',{staticClass:"container content"},[(_vm.isLoading)?_c('div',{staticClass:"flex flex-row justify-center"},[_c('Spinners')],1):_c('div',{staticClass:"notification",class:{
                    'is-danger': _vm.exhibitorSearchResults.length == 0,
                    'is-success': _vm.exhibitorSearchResults.length
                }},[(_vm.exhibitorSearchResults.length == 0)?_c('span',[_c('b',[_vm._v("We’re sorry")]),_vm._v(", we found no records matching your search criteria. ")]):_c('span',[_vm._v(" We have "),_c('b',[_vm._v(_vm._s(_vm.exhibitorSearchResults.length))]),_vm._v(" results ")])]),_c('div',{staticClass:"column has-text-weight-light"},[_vm._l((_vm.exhibitorSearchResults),function(exhibitor){return _c('exhibitor-result-card',{key:exhibitor.id,staticClass:"mb-8",attrs:{"data":exhibitor,"id":exhibitor.id,"name":exhibitor.name,"imgPath":exhibitor.logo,"contactName":exhibitor.contactName,"contactEmail":exhibitor.contactEmail,"hasFullInfo":true,"hasActionButtons":true}})}),_c('div',{staticClass:"has-text-right"},[_c('button',{staticClass:"button pill-button is-primary mb-2 has-text-weight-semibold",on:{"click":function($event){$event.preventDefault();return _vm.goToSearch()}}},[_vm._v(" BACK TO SEARCH ")])])],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }