






























































































import { Component, Vue } from "vue-property-decorator";
import Spinners from "@/components/utilities/Spinners.vue";
import ExhibitorResultCard from "@/components/shared/ExhibitorResultCard.vue";

import { mapGetters } from "vuex";

@Component({
    components: {
        ExhibitorResultCard,
        Spinners
    },
    data() {
        return {
            isLoading: false
        };
    },
    computed: {
        ...mapGetters(["exhibitorSearchResults"])
    }
})
export default class ExhibitorSearchResults extends Vue {
    goToSearch() {
        this.$router.push("/tradeshow");
    }

    get cssVariables() {
        return this.$store.getters.cssVariables;
    }
}
